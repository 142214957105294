import React from 'react'
import { ButtonContent } from './boton/ButtonContent'

const VideoPromocional = ({ linkVideo, image, title, text }) => {
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image}")`
            }}
        >
            <div className='countentVideoInfo'>
                <div className='info'>
                    <h3>{title}</h3>
                    <p>{text}</p>
                    <ButtonContent />
                </div>
                <video
                    controls
                    autoPlay
                    loop
                    className='w-[90%] md:w-[60%] mx-auto rounded-3xl relative top-[50px] md:top-[150px]'
                >
                    <source
                        src={linkVideo}
                        type='video/mp4'
                    />
                </video>
            </div>
        </div>
    )
}

export default VideoPromocional


